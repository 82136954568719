import PersonDefault from './PersonDefault';

export default {
  id: 0,
  objectType: 'com.media.medium',
  body: '',
  author: PersonDefault,
  creationTime: '0000-00-00 00:00:00',
  editor: PersonDefault,
  updateTime: '0000-00-00 00:00:00',
  voteUpCout: 0,
};
