export default {
  title: '',
  version: '',
  logo: 'https://s3.amazonaws.com/anahitapolis.com/media/logos/round_logo.png',
  license: {
    name: '',
    url: '',
  },
  website: {
    name: '',
    url: '',
  },
};
