export default {
  FIELDS: {
    IDENTIFIER: {
      MAX_LENGTH: 100,
      MIN_LENGTH: 10,
    },
    PASSWORD: {
      MAX_LENGTH: 100,
      MIN_LENGTH: 10,
    },
  },
};
