import contact from './contact.md';
import join from './join.md';
import privacy from './privacy.md';
import tos from './tos.md';
import guideline from './guideline.md';

export default {
  contact,
  join,
  privacy,
  tos,
  guideline,
};
