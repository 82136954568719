export default {
  articleAdd: '<0>{{subject}}</0> published an <1>article</1>',
  articleComment: '<0>{{subject}}</0> commented on <1>article</1>',
  documentAdd: '<0>{{subject}}</0> posted a <1>document</1>',
  documentComment: '<0>{{subject}}</0> commented on <1>document</1>',
  noteAdd: '<0>{{subject}}</0> posted a <1>note</1>',
  noteComment: '<0>{{subject}}</0> commented on <1>note</1>',
  photoAdd: '<0>{{subject}}</0> posted a <1>photo</1>',
  photoComment: '<0>{{subject}}</0> commented on <1>photo</1>',
  actorAdd: '<0>{{subject}}</0> created <1>{{object}}</1>',
  todoAdd: '<0>{{subject}}</0> created a <1>todo</1>',
  todoComment: '<0>{{subject}}</0> commented on <1>todo</1>',
  todoEnable: '<0>{{subject}}</0> enabled <1>todo</1>',
  todoDisable: '<0>{{subject}}</0> disabled <1>todo</1>',
  topicAdd: '<0>{{subject}}</0> started a <1>topic</1>',
  topicComment: '<0>{{subject}}</0> commented on <1>topic</1>',
  setAdd: '<0>{{subject}}</0> created a <1>photo set</1>',
  setComment: '<0>{{subject}}</0> commented on <1>photo set</1>',
  actorFollow: '<0>{{subject}}</0> is following <2>{{target}}</2>',
  actorFollowerAdd: '<0>{{subject}}</0> added <1>{{object}}</1> as a follower to <2>{{target}}</2>',
  actorMention: '<0>{{subject}}</0> mentioned you in a <1>post</1>',
  actorMentionComment: '<0>{{subject}}</0> mentioned you in a <1>comment</1>',
  voteup: '<0>{{subject}}</0> liked your <1>post</1>',
};
