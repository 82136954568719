const assignmentAppDefault = {
  id: 0,
  name: '',
  options: [
    'optional',
    'always',
    'never',
  ],
  selected: 'never',
  access: 0,
  is_optional: true,
};

export default {
  identifier: '',
  apps: [assignmentAppDefault],
};
